import { Link } from 'gatsby'
import React, { Component } from 'react'

export default class Article extends Component<{title: string, date: Date, content: string}> {
    render() {
        return (
            <div className="article">
                <span className="article__date fs-open-st">{new Date(this.props.date).toISOString().split('T')[0]}</span>
                <h1 className="article__title">{this.props.title}</h1>
                <p className="article__content">{this.props.content.substr(0, 150) + "..."}</p>

                <Link className="article__readmore" to={`/inlägg/${new Date(this.props.date).toISOString().split('T')[0]}/${this.props.title.toLowerCase().split(" ").join("-")}`}>Läs mer</Link>
            </div>
        )
    }
}
