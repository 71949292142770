import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import Article from './Article'

export default function Articles(props: {bottom: boolean, amount?: number}) {
    const data = useStaticQuery(graphql`
            query {
        allContentfulInlagg {
            edges {
            node {
                title
                date
                content {raw}
            }
            }
        }
        }
    `)

    return (
        <>
            <section className="articles-mobile">
                <div className="p-margin">
                    <h1 className="fs-mont-sh">Aktivitet</h1>
                    {data.allContentfulInlagg.edges.slice(0, props.amount || data.allContentfulInlagg.edges.length).map(a => (
                        <Link style={{display:"block", color:"#000", margin:"1rem 0", textDecoration:"none"}} to={`/inlägg/${new Date(a.node.date).toISOString().split('T')[0]}/${a.node.title.toLowerCase().split(" ").join("-")}`}>
                          <Article title={a.node.title} date={new Date(a.node.date)} content={documentToPlainTextString(JSON.parse(a.node.content.raw))} />
                        </Link>
                    ))}
                </div>
            </section>

            <section className="articles" style={props.bottom ? {
                position: "absolute",
                left: "0",
                width: "100%"
            } : {}}>
                <div className="p-margin">
                    <h1 className="fs-mont-sh">Aktivitet</h1>
                    {props.amount != null ? (
                        <div className="estates__content__heading__seemore">
                            <Link to="/aktivitet"><button className="btn-round">Se fler</button></Link>
                        </div>
                    ) : ""}
                    <hr />
                    <div className="articles__container">
                    {data.allContentfulInlagg.edges.slice(0, props.amount || data.allContentfulInlagg.edges.length).map(a => (
                        <Article title={a.node.title} date={new Date(a.node.date)} content={documentToPlainTextString(JSON.parse(a.node.content.raw))} />
                    ))}
                    </div>
                </div>
            </section>
        </>
    )
}
